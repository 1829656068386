<template>
  <v-app style="background-color:#f8f9fb" >
  
    <v-dialog v-model="showFilter" persistent max-width="300">
      <v-card>
        <v-card-title>
         Mensagem
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          A Diferença entre as datas não pode ser superior a 40 dias.
        </v-card-text>
        <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
          <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-container >
      <v-col cols="12" >
        <filtro-component :opcoes="opcoes" :modos="modos" :tipos="tipos" :calendar="'ano'" :produtos="tipos_funerarios" 
        @filtrar="filterData" :title_opcao="'Situação'"
          ></filtro-component>
      </v-col>
    </v-container>
  


    <v-container style="background-color:#eee"  
    id="sepultamento"
      fluid
      tag="section">
      
  
  
    <v-row>
  
      <v-col
      cols="12"
       sm="6"
       lg="3"
     >
      
       <base-material-stats-card
         color="blue"  elevation="1"
         icon="mdi-flower"
         title="Sepultados (Nº)"
         sub-icon-color="black"
         :value='this.cards.sepultados.toString()'
         :valuex='false'
         :sub-text="'Clientes com Plano : ' + this.cards.clientes_plano.toString()"
       />
      
     </v-col>
  
     <v-col
     cols="12"
      sm="6"
      lg="3"
    >
     
      <base-material-stats-card
        color="green"  elevation="1"
        icon="mdi-rocket"
        title="Imediato (Nº)"
        sub-icon-color="blue"
        :value='this.cards.imediato.toString()'
        :valuex='false'
        :sub-text="'Rec. Vendas e Taxas: ' + formatarMoeda(this.cards.valor_imediato)"
      />
     
    </v-col>

    <v-col
    cols="12"
     sm="6"
     lg="3"
   >
    
     <base-material-stats-card
       color="orange" elevation="1"
       icon="mdi-lifebuoy"
       title="Preventivo (Nº)"
       sub-icon-color="blue"
       :value='this.cards.preventivo.toString()'
       :valuex='false'
       :sub-text="'Receita de Taxas: ' + formatarMoeda(this.cards.valor_preventivo)"
     />
    
   </v-col>

     <v-col
    cols="12"
     sm="6"
     lg="3"
   >
    
     <base-material-stats-card
       color="black" elevation="1"
       icon="mdi-candle"
       title="Óbitos - AL (Nº)"
       sub-icon-color="blue"
       :value='this.cards.obitos_al.toString()'
       :valuex='false'
       :sub-text="'Óbitos em Maceió: ' + this.cards.obitos_ma.toString()"
     />
    
   </v-col>
 
  
    </v-row>
  
    <v-row>
      
      <v-col cols="6">

        <v-card v-if="!sepultamento_empresa_load" color="white" dark style="height: 350px !important;background-color:#f1f3f7 !important" elevation="0">
          <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;">Sepultamento por Cemitério</v-card-title>
          <apexcharts type="bar" :options="generateBarChartOptions(this.gf_1_label, this.gf_1_color)" :series="gf_1_series"></apexcharts>
        </v-card>
        <v-card v-if="sepultamento_empresa_load" color="white" style="height:220px" elevation="0">
          <v-card-text class="text-center justify-center align-center "> <!-- Centraliza horizontalmente o conteúdo de texto -->
            <content-loader  primaryColor="#f1f3f7" secondaryColor="#ffffff"  :speed="1" height="90"></content-loader> 
          </v-card-text>      
       </v-card>
      </v-col>
  
      <v-col cols="6">

        
        <v-card v-if="!sepultamento_funeraria_load"  color="white" dark   style="height: 350px !important;background-color:#f1f3f7 !important" elevation="0">
          <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;">Sepultamento por Funerária</v-card-title>
          <v-card-text>
            <!-- Adicione o gráfico aqui -->
            <div style="max-height: 278px; overflow-y: scroll;">
            <apexcharts width="450" style="margin-top:10px !important"  height="300" type="bar" :options="generateBarChartOptions(this.gf_2_label, this.gf_2_color)" :series="gf_2_series"></apexcharts>
            </div> 
          
          </v-card-text>
        </v-card>

        <v-card v-if="sepultamento_funeraria_load" color="white" style="height:240px" elevation="0">
          <v-card-text class="text-center justify-center align-center "> <!-- Centraliza horizontalmente o conteúdo de texto -->
            <content-loader  primaryColor="#f1f3f7" secondaryColor="#ffffff"  :speed="1" height="90"></content-loader> 
          </v-card-text>      
       </v-card>


      </v-col>
  
    </v-row>

    <v-row>
    
      <v-col cols="6">

        <v-card v-if="!imediato_parcelamento_load" color="white" dark style="height: 350px !important;background-color:#f1f3f7 !important" elevation="0" >
          <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;">Receita de Imediato Por Parcelamento</v-card-title>
          <apexcharts type="pie"   height="450"  style="height: 450px !important;" :options="generatePieChartOptions(this.gf_3_label, this.gf_3_color)" :series="gf_3_series"></apexcharts>
        </v-card>
        <v-card v-if="imediato_parcelamento_load" color="white" style="height:280px" elevation="0">
          <v-card-text class="text-center justify-center align-center "> <!-- Centraliza horizontalmente o conteúdo de texto -->
            <content-loader  primaryColor="#f1f3f7" secondaryColor="#ffffff"  :speed="1" height="90"></content-loader> 
          </v-card-text>      
       </v-card>

      </v-col>

      <v-col cols="6">


        <v-card v-if="!preventivo_parcelamento_load" style="height: 350px !important;background-color:#f1f3f7 !important" color="white" dark  elevation="0">
          <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;">Receita de Taxas por Parcelamento</v-card-title>
          <apexcharts type="pie"  height="300"  style="height: 450px !important;"  :options="generatePieChartOptions(this.gf_4_label, this.gf_4_color)" :series="gf_4_series"></apexcharts>
        </v-card>
        <v-card v-if="preventivo_parcelamento_load" color="white" style="height:280px" elevation="0">
          <v-card-text class="text-center justify-center align-center "> <!-- Centraliza horizontalmente o conteúdo de texto -->
            <content-loader  primaryColor="#f1f3f7" secondaryColor="#ffffff"  :speed="1" height="90"></content-loader> 
          </v-card-text>      
       </v-card>


      </v-col>

      <v-col cols="6">


        <v-card v-if="!sepultamento_plano_load"  color="white" dark   style="height: 350px !important;background-color:#f1f3f7 !important" elevation="0"  >
          <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;">Sepultamento por Plano</v-card-title>
          <v-card-text>
            <!-- Adicione o gráfico aqui -->
            <div style="max-height: 400px; overflow-y: scroll;">

            <apexcharts width="450" height="300" type="bar" :options="generateBarChartOptions(this.gf_5_label, this.gf_5_color)" :series="gf_5_series"></apexcharts>
          
            </div>
          </v-card-text>
        </v-card>

        <v-card v-if="sepultamento_plano_load" color="white" style="height:240px" elevation="0">
          <v-card-text class="text-center justify-center align-center "> <!-- Centraliza horizontalmente o conteúdo de texto -->
            <content-loader  primaryColor="#f1f3f7" secondaryColor="#ffffff"  :speed="1" height=90></content-loader> 
          </v-card-text>      
       </v-card>


      </v-col>


      

     </v-row> 
  
     <v-divider></v-divider>

    <v-row>
  
      <v-col
      cols="12"
       sm="6"
       lg="3"
     >
      
       <base-material-stats-card
         color="blue" elevation="1"
         icon="mdi-coffin"
         title="Jazigos (Nº)"
         sub-icon-color="black"
          :value="this.cards.total_jazigo.toString()"
          :valuex='false'
          :sub-text="'Jazigo Vendidos : ' + this.cards.total_jazigo_vendido.toString()"

       />
      
     </v-col>
  
     <v-col
     cols="12"
      sm="6"
      lg="3"
    >
     
      <base-material-stats-card
        color="green" elevation="1"
        icon="mdi-archive"
        title="Gavetas (Nº)"
        sub-icon-color="blue"
        :value="this.cards.total_gavetas.toString()"
        :sub-text="'Gavetas Disponível: ' + this.cards.total_gavetas_disponivel.toString()"
        :valuex='false'
        
      />

      
     
    </v-col>

    <v-col
    cols="12"
     sm="6"
     lg="3"
   >
    
     <base-material-stats-card
       color="orange"
       icon="mdi-flower" elevation="1"
       title="Sepultados (Nº)"
       sub-icon-color="blue"
       :value='this.cards.sepultados_todos.toString()'
       :sub-text="'Exumados : ' + this.cards.total_exumados.toString()"
       :valuex='false'
     />
    
   </v-col>

     <v-col
    cols="12"
     sm="6"
     lg="3"
   >
    
     <base-material-stats-card
       color="purple"
       icon="mdi-fire" elevation="1"
       title="Cremados (Nº)"
       sub-icon-color="blue"
       :value='this.cards.total_cremados.toString()'
       :sub-text="'Venda imediata : ' + this.cards.total_columbario_disponivel.toString()"
       :valuex='false'
      
     />
    
   </v-col>
 
  
    </v-row>
      
    </v-container>
  
  </v-app> 
  </template>
  
  <script>
  import VueApexCharts from "vue-apexcharts";
  import Sepultado from '../../services/sepultado'
  import axios from 'axios'
  import FiltroComponent from "./components/Filtro.vue"; // Certifique-se de que o caminho esteja correto
  import { ContentLoader } from 'vue-content-loader'

  
  
    export default {
    components: {
      apexcharts: VueApexCharts,
      FiltroComponent,
      ContentLoader,
    },

    created() {
      this.selectedStartDate = this.getFirstDayOfYear(),
      this.selectedEndDate =  this.formatDateBR(new Date())
     
    },
    
    mounted() {
  
      this.dashboard();
      this.apiExterna("AL", ""); // Chame apiExterna com o ano selecionado
      this.apiExterna("Maceio", "&state=AL"); // Chame apiExterna com o ano selecionado

      this.gf01();
      this.gf02();
      this.gf03();
      this.gf04();
      this.gf05();

    },
  
      name: 'Sepultamento',
   
      data () { 
        return {
         
          empresa: 'Todas',
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "",
          selectMode: "",
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          opcoes: [],
          modos: [],
          showFilter: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "Todas"],
          tipos: ["Todos"],

          cards: {

              sepultados: 0,
              sepultados_json: '',

              sepultados_todos: 0,
              sepultados_todos_json: '',

          
              imediato: 0,
              imediato_json: '',

              valor_imediato: 0,
              valor_imediato_json: '',

              preventivo: 0,
              preventivo_json: 0,

              valor_preventivo: 0,
              valor_preventivo_json: '',


              obitos_al: 0,
              obitos_ma: 0,

              clientes_plano: 0,
              clientes_plano_json:0,

              receita_venda:0,
              receita_venda_json: 0,

              receita_taxa: 0,
              receita_taxa_json: 0,

              total_jazigo: 0,
              total_jazigo_json: 0,

              total_jazigo_vendido: 0,
              total_jazigo_vendido_json: 0,

              total_gavetas: 0,
              total_gavetas_json: 0,

              total_gavetas_disponivel: 0,
              total_gavetas_disponivel_json: 0,

              total_exumados: 0,
              total_exumados_json: 0,

              total_cremados: 0,
              total_cremados_json: 0,

              total_columbario_disponivel: 0,
              total_columbario_disponivel_json: 0,
            

              
          },


        tipos_funerarios: [
                "Todos",
              ],

          
            sepultamento_empresa_json:0,
            sepultamento_empresa_load:true,
            gf_1_label: [],
            gf_1_series: [{data: []}],
            gf_1_color: [
                        '#3CB371', '#E9967A', '#FF8C00', '#FFD700', '#F5F5DC', '#D2691E', '#FF1493',
                        '#FF0000', '#7FFF00', '#F4A460', '#D2B48C', '#00BFFF', '#DC143C', '#7B68EE',
                        '#FFEBCD', '#FFA500', '#FFE4C4', '#00FA9A', '#EEE8AA', '#FFFF00', '#F0E68C'
                        ],
            sepultamento_funeraria_json:0,
            sepultamento_funeraria_load:true,

            gf_2_label: [],
            gf_2_color:  [
                        '#3CB371', '#E9967A', '#FF8C00', '#FFD700', '#F5F5DC', '#D2691E', '#FF1493',
                        '#FF0000', '#7FFF00', '#F4A460', '#D2B48C', '#00BFFF', '#DC143C', '#7B68EE',
                        '#FFEBCD', '#FFA500', '#FFE4C4', '#00FA9A', '#EEE8AA', '#FFFF00', '#F0E68C'
                        ],
            gf_2_series: [{data: []}],

            imediato_parcelamento_json:0,
            imediato_parcelamento_load:true,

            gf_3_label: [],
            gf_3_series: [{ data: []}],
            gf_3_color:  ['#FFA533', '#FFC654', '#9B59B6', '#2ECC71', '#1ABC9C'], 

            preventivo_parcelamento_json:0,
            preventivo_parcelamento_load:true,

            gf_4_label: [],
            gf_4_series: [{ data: []}],
            gf_4_color:  [
                        '#3CB371', '#E9967A', '#FF8C00', '#FFD700', '#F5F5DC', '#D2691E', '#FF1493',
                        '#FF0000', '#7FFF00', '#F4A460', '#D2B48C', '#00BFFF', '#DC143C', '#7B68EE',
                        '#FFEBCD', '#FFA500', '#FFE4C4', '#00FA9A', '#EEE8AA', '#FFFF00', '#F0E68C'
                        ], 

            sepultamento_plano_json:0,
            sepultamento_plano_load:true,

            gf_5_label: [''],
            gf_5_series: [{data: []}],
            gf_5_color:  [
                        '#3CB371', '#E9967A', '#FF8C00', '#FFD700', '#F5F5DC', '#D2691E', '#FF1493',
                        '#FF0000', '#7FFF00', '#F4A460', '#D2B48C', '#00BFFF', '#DC143C', '#7B68EE',
                        '#FFEBCD', '#FFA500', '#FFE4C4', '#00FA9A', '#EEE8AA', '#FFFF00', '#F0E68C'
                        ],
            
        }
    },
  
      methods: {



        getFirstDayOfYear() {
          const dataAtual = new Date();
          const primeiroDiaDoAno = new Date(dataAtual.getFullYear(), 0, 1); // Janeiro é representado como 0
          return this.formatDateBR(primeiroDiaDoAno);
        },


        formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },

        formatDateBRAPI(date) {
                if (!date) return '';
                const parsedDate = new Date(this.formatDateBR(date));
                const year = parsedDate.getFullYear();
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                return `${year}-${month}-${day}`;
              },


       filterData(valores) {
         this.isLoading = true;
         let nova_consulta = false;

         this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;

        

          if (this.selectedStartDate !== valores.selectedStartDate || this.selectedEndDate !== valores.selectedEndDate) {
            nova_consulta = true;
          }

          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;



        if(nova_consulta == true){
            this.dashboard();
            this.gf01();
            this.gf02();
            this.gf03();
            this.gf04();
            this.gf05();
            this.isLoading = false;
            
           
        }else{
            this.recerregarDados();
            this.gf04();
            
        }

        this.apiExterna("AL", ""); // Chame apiExterna com o ano selecionado
        this.apiExterna("Maceio", "&state=AL"); // Chame apiExterna com o ano selecionado

      },

      recerregarDados(){

          const total_a = this.calcularTotalAcumulado(this.cards.sepultados_json, this.selectedYear, this.selectedType, this.selectedCompany );                      
          this.cards.sepultados = total_a;

          const total_f = this.calcularTotalAcumulado(this.cards.clientes_plano_json, this.selectedYear, this.selectedType, this.selectedCompany );                      
          this.cards.clientes_plano = total_f;

          const total_b = this.calcularTotalAcumulado(this.cards.imediato_json, this.selectedYear, this.selectedType, this.selectedCompany );                      
          this.cards.imediato = total_b;

          const total_c = this.calcularTotalAcumulado(this.cards.valor_imediato_json, this.selectedYear, this.selectedType, this.selectedCompany );                      
          this.cards.valor_imediato = total_c;

          const total_d = this.calcularTotalAcumulado(this.cards.valor_preventivo_json, this.selectedYear, this.selectedType, this.selectedCompany );                      
          this.cards.valor_preventivo = total_d;

          const total_e = this.calcularTotalAcumulado(this.cards.preventivo_json, this.selectedYear, this.selectedType, this.selectedCompany );                      
          this.cards.preventivo = total_e;

          this.filtrarTotalGraficos(this.cards.sepultamento_empresa_json, this.selectedYear, this.selectedType, this.selectedCompany, "label", "qtd", "1");                      

          this.filtrarTotalGraficos(this.cards.imediato_parcelamento_json, this.selectedYear, this.selectedType, this.selectedCompany, "label", "qtd", "3");                      

          this.filtrarTotalGraficos(this.cards.sepultamento_funeraria_json, "Todos", "Todos", "Todas", "label", "qtd", "2");                      

          this.filtrarTotalGraficos(this.cards.sepultamento_plano_json, "Todos", "Todos", this.selectedCompany, "label", "qtd", "5");                      

          this.filtrarTotalGraficos(this.cards.preventivo_parcelamento_json, this.selectedYear, this.selectedType, this.selectedCompany, "label", "qtd", "4");                      


          this.isLoading = false;
      },
        
  
      async gf01() {
          try {
            const response = await Sepultado.sepultameto_empresa(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate));
            if (response.status === 200) {
              // Extrair os dados da resposta da API
              // Extrair os dados da resposta da API
              this.cards.sepultamento_empresa_json = response.data;
              this.filtrarTotalGraficos(this.cards.sepultamento_empresa_json, "Todos", "Todos", this.selectedCompany, "label", "qtd", "1");                      


            }
          } catch (error) {
            // Lidar com erros, se necessário
            console.error(error);
          }finally {
            }
        },

        async gf02() {
          try {
            const response = await Sepultado.sepultameto_funeraria(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate));
            if (response.status === 200) {
              // Extrair os dados da resposta da API
              // Extrair os dados da resposta da API
              this.cards.sepultamento_funeraria_json = response.data;
              this.filtrarTotalGraficos(this.cards.sepultamento_funeraria_json, "Todos", "Todos", "Todas", "label", "qtd", "2");                      


            }
          } catch (error) {
            // Lidar com erros, se necessário
            console.error(error);
          }finally {
            }
        },

        

        async gf03() {
          try {
            const response = await Sepultado.imediato_parcelamento(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate));
            if (response.status === 200) {
  
              this.cards.imediato_parcelamento_json = response.data;
              this.filtrarTotalGraficos(this.cards.imediato_parcelamento_json, this.selectedYear, this.selectedType, this.selectedCompany, "label", "qtd", "3");                      


            }
          } catch (error) {
            // Lidar com erros, se necessário
            console.error(error);
          }finally {
            }
        },


        async gf04(){        
          try {
            const response = await Sepultado.preventivo_parcelamento(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany);
            if (response.status === 200) {
  
              this.cards.preventivo_parcelamento_json = response.data;
              this.filtrarTotalGraficos(this.cards.preventivo_parcelamento_json, this.selectedYear, this.selectedType, this.selectedCompany, "label", "qtd", "4");                      
              this.isLoading = false;

            }
          } catch (error) {
            // Lidar com erros, se necessário
            console.error(error);
          } finally {
            }    

        },

        async gf05() {
          try {
            const response = await Sepultado.sepultamento_plano(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate));
            if (response.status === 200) {
  
              this.cards.sepultamento_plano_json = response.data;
              this.filtrarTotalGraficos(this.cards.sepultamento_plano_json, this.selectedYear, this.selectedType, this.selectedCompany, "label", "qtd", "5");                      
              this.isLoading = false;

            }
          } catch (error) {
            // Lidar com erros, se necessário
            console.error(error);
          }finally {
            }  
        },


          

      generatePieChartOptions(labels, colors) {
              return {

                chart: {
                  width: 440, // Largura fixa
                  height: 380, // Altura fixa
                                  type: 'pie',
                                },
                                labels: labels,
                              
                  
                  plotOptions: {
                    pie: {
                      dataLabels: {
                        offset: -10
                      }
                    }
                  },

                                colors: colors, // Defina as cores desejadas aqui
                                dataLabels: {
                    formatter(val, opts) {
                      const name = opts.w.globals.labels[opts.seriesIndex]
                      return [val.toFixed(1) + '%']
                    }
                  },
                                responsive: [{
                                  breakpoint: 400,
                                  options: {
                                    chart: {
                                      width: 500
                                    },
                                    legend: {
                                      position: 'footer'
                                    }
                                  }
                                }]

                      
                }

              
            },
            
      generateBarChartOptions(labels, colors) {
                return {
                  chart: {
                            type: 'bar',
                            width: 480, // Largura fixa
                            height: 280, // Altura fixa
                          },
                          plotOptions: {
                            bar: {
                                barHeight: '100%',
                                distributed: true,
                                horizontal: true,
                                dataLabels: {
                                  position: 'bottom'
                                },
                              }
                          },
                          dataLabels: {
                           
                            enabled: true,
                              textAnchor: 'start',
                              style: {
                                colors: ['#000000'],
                                fontWeight: 'normal', // Defina a espessura da fonte como 'normal' para remover o negrito
                                fontSize: '12px',     // Altere o tamanho da fonte aqui, por exemplo, 14 pixels

                              },
                              formatter: function (val, opt) {
                                const percent = ((val / opt.w.globals.seriesTotals[opt.seriesIndex]) * 100).toFixed(2) + '%';
                                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + " - (" + percent + ")";
                              },
                              offsetX: 0,
                              dropShadow: {
                                enabled: false
                              }
                            },
                          colors: colors, // Defina as cores aqui
                          stroke: {
                            width: 1,
                            colors: ['#fff']
                          },
                          yaxis: {
                            labels: {
                              show: false
                            }
                          },
                          grid: {
                            show: false, // Defina para "false" para ocultar as linhas de grade
                          },
                          xaxis: {
                            categories: labels,
                            trim: true,
                            labels: {
                              show: false,
                            }
                          },
                          
                };
              },


      calcularTotalAcumulado(lista, adesao, tipo, nome_empresa) {
          let totalAcumulado = 0;

          if (adesao === "Todos") {
            adesao = null;
          }
          if (tipo === "Todos") {
            tipo = null;
          }
        
          for (const item of lista) {
            const empresasSplit = nome_empresa.split(',');
            const empresas = empresasSplit.map(e => e.trim());
            const empresaIgual = empresas.includes(item.empresa) || empresas.includes("TODAS") || empresas.includes("Todas");
            const adesaoIgual = !adesao || item.adesao === adesao
            const tipoIgual = !tipo || item.tipo === tipo;

            if (adesaoIgual && tipoIgual && empresaIgual) {
              totalAcumulado += item.qtd;
            }
          }

          return totalAcumulado;
      },

      filtrarTotalGraficos(lista, adesao, tipo, nome_empresa, campoLabel, campoSerie, gf) {
         
        let totalAcumulado = 0;

          const newLabels = [];
          const newSeries = [];


          if (adesao === "Todos") {
            adesao = null;
          }
          if (tipo === "Todos") {
            tipo = null;
          }

         

          lista.sort((a, b) => b[campoSerie] - a[campoSerie]);

          for (let i = 0; i < lista.length; i++) {
              const item = lista[i];
            const empresasSplit = nome_empresa.split(',');
            const empresas = empresasSplit.map(e => e.trim());
            const empresaIgual = empresas.includes(item.empresa) || empresas.includes("TODAS") || empresas.includes("Todas");
            const adesaoIgual = !adesao || item.adesao === adesao
            const tipoIgual = !tipo || item.tipo === tipo;

       

              if (adesaoIgual && tipoIgual && empresaIgual) {
                //const index = newLabels.indexOf(item[campoLabel]);
                
               

                if (newSeries.length < 8) {
                  // Adicione os 8 maiores valores
                  newLabels.push(item[campoLabel]);
                  newSeries.push(item[campoSerie]);
                } else {
                  // Acumule os valores restantes em "Outros"
                  totalAcumulado += item[campoSerie];
                }

                
                /*
                if (index !== -1) {
                  newSeries[index] += item[campoSerie];
                } else {
                  newLabels.push(item[campoLabel]);
                  newSeries.push(item[campoSerie]);
                }
                */


              }
            }

            if(totalAcumulado>0){
              newLabels.push("Outros");
              newSeries.push(totalAcumulado);
            }

            if(gf=== '5'){
              this.gf_5_label = newLabels;
              this.gf_5_series = [{ data: newSeries }];
              this.sepultamento_plano_load = false;

            }else if(gf=== '4'){
              this.gf_4_label = newLabels;
              this.gf_4_series = newSeries;
              this.preventivo_parcelamento_load = false;

            }else if(gf=== '3'){
              this.gf_3_label = newLabels;
              this.gf_3_series = newSeries;
              this.imediato_parcelamento_load = false;

            }else if(gf=== '2'){
              this.gf_2_label = newLabels;
              this.gf_2_series = [{ data: newSeries }];
              this.sepultamento_funeraria_load = false;

            }else if(gf=== '1'){
              this.gf_1_label = newLabels;
              this.gf_1_series = [{ data: newSeries }];
              this.sepultamento_empresa_load = false;

            }
      },


      getRandomColorFromSet(numColors, tipo) {
       
        let predefinedColors = [];
          if(tipo === 'pizza'){

          predefinedColors = ['#0000FF', '#00008B', '#0000CD', '#4682B4', '#00FF00', '#00BFF', '#0000FF', 
          '#006400', '#FF0000', '#008000', '#3CB371', '#7FFF00', '#800000', '#8A2BE2', '#EE82EE'
          , '#FF1493', '#EE82EE', '#B22222', '#FA8072', '#EE82EE', '#E9967A', '#FF8C00', '#FFA500'
          , '#FFD700', '#F0E68C',  '#FFE4B5'];

          }else{
            predefinedColors = ['#DC143C', '#FF1493', '#E9967A', '#FF0000', '#FFD700', '#FFA500', '#FF8C00',
           '#F0E68C', '#F5F5DC',  '#FFEBCD', '#FFE4C4', '#EEE8AA', '#FFD700', 
           , '#D2B48C', '#7B68EE','#00BFFF' ,'#00FA9A','#3CB371','#7FFF00','#D2691E','#FFFF00','#F4A460' ];


          }



        const randomColors = [];

          for (let i = 0; i < numColors; i++) {
            const randomIndex = Math.floor(Math.random() * predefinedColors.length);
            randomColors.push(predefinedColors[randomIndex]);
          }
          return randomColors;

      },


      async dashboard() {
                    Sepultado.sepultado(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate)).then(response => {
                      if(response.status === 200){
                         this.cards.sepultados_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.sepultados_json, "Todos", this.selectedType, this.selectedCompany );                      
                         console.log('OK', this.cards.sepultados_json )
                         this.cards.sepultados = total_a;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  


                    const dataAtual = new Date();
                    const dataAtualFormatada = `${dataAtual.getFullYear()}-${String(dataAtual.getMonth() + 1).padStart(2, '0')}-${String(dataAtual.getDate()).padStart(2, '0')}`;

                    Sepultado.sepultado('1970-01-01', dataAtualFormatada).then(response => {
                      if(response.status === 200){
                      
                         this.cards.sepultados_todos_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.sepultados_todos_json, "Todos", this.selectedType, this.selectedCompany );                      
                         this.cards.sepultados_todos = total_a;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  

                     Sepultado.sepultado_plano(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate),  this.selectedType, this.selectedCompany).then(response => {
                      if(response.status === 200){
                      
                        this.cards.clientes_plano_json = response.data;
                        const total_a = this.calcularTotalAcumulado(this.cards.clientes_plano_json, "Todos", this.selectedType, this.selectedCompany );                      
                        this.cards.clientes_plano = total_a;


                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  
                  Sepultado.total_imediato(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate)).then(response => {
                      if(response.status === 200){
                      
                         this.cards.imediato_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.imediato_json, "Todos", this.selectedType, this.selectedCompany );                      
                         this.cards.imediato = total_a;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  

                  Sepultado.valor_imediato(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate)).then(response => {
                      if(response.status === 200){
                      
                         this.cards.valor_imediato_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.valor_imediato_json, "Todos", this.selectedType, this.selectedCompany );                      
                         this.cards.valor_imediato = total_a;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  

                    Sepultado.total_preventivo(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate)).then(response => {
                      if(response.status === 200){
                      
                         this.cards.preventivo_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.preventivo_json, "Todos", this.selectedType, this.selectedCompany );                      
                         this.cards.preventivo = total_a;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  

                  Sepultado.valor_preventivo(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate)).then(response => {
                      if(response.status === 200){
                      
                         this.cards.valor_preventivo_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.valor_preventivo_json, "Todos", this.selectedType, this.selectedCompany );                      
                         this.cards.valor_preventivo = total_a;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  

                    Sepultado.total_jazigo(this.empresa).then(response => {
                      if(response.status === 200){
                      
                         this.cards.total_jazigo_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.total_jazigo_json, "Todos", this.selectedType, this.selectedCompany );                      
                         this.cards.total_jazigo = total_a;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  

                    Sepultado.jazigo_vendido(this.empresa).then(response => {
                      if(response.status === 200){
                      
                         this.cards.total_jazigo_vendido_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.total_jazigo_vendido_json, "Todos", this.selectedType, this.selectedCompany );                      
                         this.cards.total_jazigo_vendido = total_a;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  

                    Sepultado.total_gavetas(this.empresa).then(response => {
                      if(response.status === 200){
                      
                         this.cards.total_gavetas_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.total_gavetas_json, "Todos", this.selectedType, this.selectedCompany );                      
                         this.cards.total_gavetas = total_a;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  

                    Sepultado.total_gavetas_disponivel(this.empresa).then(response => {
                      if(response.status === 200){
                      
                         this.cards.total_gavetas_disponivel_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.total_gavetas_disponivel_json, "Todos", this.selectedType, this.selectedCompany );                      
                         this.cards.total_gavetas_disponivel = total_a;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    }) 


                    

                    Sepultado.total_exumados(this.empresa).then(response => {
                      if(response.status === 200){
                      
                         this.cards.total_exumados_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.total_exumados_json, "Todos", this.selectedType, this.selectedCompany );                      
                         this.cards.total_exumados = total_a;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  

                    Sepultado.total_cremados(this.empresa).then(response => {
                      if(response.status === 200){
                      
                         this.cards.total_cremados_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.total_cremados_json, "Todos", this.selectedType, this.selectedCompany );                      
                         this.cards.total_cremados = total_a;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  

                    Sepultado.total_columbario_disponivel(this.empresa).then(response => {
                      if(response.status === 200){
                      
                         this.cards.total_columbario_disponivel_json = response.data;
                         const total_a = this.calcularTotalAcumulado(this.cards.total_columbario_disponivel_json, "Todos", this.selectedType, this.selectedCompany );                      
                         this.cards.total_columbario_disponivel = total_a;

                      } 
                    }).catch(e => {    
                    }).finally(() => {
                    })  


                   

                    

                    
                    


      },

      formatarMoeda(valor) {
        // Use a função toLocaleString() para formatar como moeda
        return valor.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      },

      async apiExterna(valor, complemento){
        
          //const dI = this.formatDateBRAPI(this.selectedStartDate);
          //const dF = this.formatDateBRAPI(this.selectedEndDate);


          const dI = converteDataParaFormatoISO(this.selectedStartDate);
          const dF = converteDataParaFormatoISO(this.selectedEndDate);
          
          const apiUrl = "https://transparencia.registrocivil.org.br/api/record/death?start_date="+dI+"&end_date="+dF+""+complemento;
          axios.get(apiUrl)
          .then(response => {
            // Filtra o JSON para obter apenas o objeto com "name" igual a "AL"
          

                  if (response.data && response.data.data) {
                      const data = response.data.data;
                      
                      // Encontre o objeto com "name" igual a "AL"
                      const alagoasData = data.find(item => item.name === valor);
            
                      if (alagoasData) {
                        // Extraia o valor total e retorne como inteiro
                        const totalAL = parseInt(alagoasData.total);
                        
                        console.log("Valor URL ", apiUrl);
                        console.log("Valor valor ", valor);
                        console.log("Valor RE ", totalAL);
                        if(valor === 'AL'){
                          this.cards.obitos_al = totalAL;
                        }else if(valor === 'Maceio'){
                          this.cards.obitos_ma = totalAL;
                        }

                        return totalAL;
                      }
                    }
              
                    // Se não encontrar, retorne 0
                    return 0;
            })
      .catch(error => {
        console.error("Valor ERROR", error);
    });

        
    },
    }
  }

  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }

  
  </script>
  
  <style scoped>
    .v-progress-circular {
      margin: 1rem;
    }

    .custom-gray-background {
      height: 80px;
    }
    
  
    

    .row {
      display: flex;
      flex-wrap: wrap;
       flex: 0 1 auto !important; 
      margin: -12px;
  }


    </style>
  